import { FC } from "react"

import { useDateLocale, useLocalizedText } from "src/components/state/localization"
import { useMounted } from "src/components/utils/useMounted"
import { DateLocale } from "src/config"
import { StatsData } from "src/types/app"

function formatDate(date: string, dateLocale: DateLocale) {
  return new Date(date).toLocaleDateString(dateLocale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
}

export type StatsSectionProps = {
  stats: StatsData
}

export const StatsSection: FC<StatsSectionProps> = ({ stats }) => {
  const T = useLocalizedText({ de, en })
  const dateLocale = useDateLocale()
  const mounted = useMounted()

  if (!stats) {
    return null
  }

  const lastUpdatedFormatted = formatDate(stats.lastUpdated, dateLocale)

  return (
    <section className="text-sm" aria-label={T.landmark}>
      <div>
        {stats.totalEntries} {T.numEntries}, {stats.totalFileSizeBytes} {stats.totalFileSiteUnit}
      </div>

      <div>
        {T.lastUpdated}: {mounted && lastUpdatedFormatted}
      </div>
    </section>
  )
}

const de = {
  landmark: "Statistik",
  numEntries: "Einträge",
  lastUpdated: "Zuletzt aktualisiert",
}

const en: typeof de = {
  landmark: "Stats",
  numEntries: "Entries",
  lastUpdated: "Last updated",
}
