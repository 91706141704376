import Router from "next/router"
import { FC, useEffect } from "react"

import { Select } from "src/components/generic/Select"
import { useLocale, useLocalizedText } from "src/components/state/localization"
import { AppLocale } from "src/config"
import { OptionData } from "src/types/app"
import { Icon } from "../generic/Icon"

const localeOptions: OptionData[] = [
  {
    value: "en",
    text: "English",
  },
  {
    value: "de",
    text: "Deutsch",
  },
]

async function updateLocalePath(locale: string) {
  await Router.push(Router.asPath, undefined, { locale, scroll: false })
}

async function updateLocaleCookie(locale: string) {
  const cookie = (await import("js-cookie")).default

  cookie.set("NEXT_LOCALE", locale, { expires: 365 })
}

export const LocaleSelect: FC = () => {
  const T = useLocalizedText({ de, en })
  const locale = useLocale()

  const setLocale = (locale: AppLocale) => {
    updateLocalePath(locale)
    updateLocaleCookie(locale)
  }

  useEffect(() => {
    updateLocaleCookie(locale)
  }, [locale])

  return (
    <section aria-label={T.landmark} className="flex gap-2 items-center">
      <Icon name="world" className="w-8" />

      <Select
        options={localeOptions}
        value={locale}
        onChange={setLocale}
        title={T.changeLocale}
        aria-label={T.changeLocale}
      />
    </section>
  )
}

const de = {
  landmark: "Sprache",
  changeLocale: "Sprache ändern",
}

const en: typeof de = {
  landmark: "Language",
  changeLocale: "Change Language",
}
