import { create as createStore } from "zustand"

type State = {
  loading: boolean
}

const defaultState: State = {
  loading: true,
}

const store = createStore(() => defaultState)

export function setLoading(loading: boolean) {
  store.setState({ loading })
}

export function useLoading() {
  return store((state) => state.loading)
}
