import { FC, useEffect } from "react"

import { Select } from "src/components/generic/Select"
import { useLocalizedText } from "src/components/state/localization"
import { OptionData } from "src/types/app"
import { Icon } from "../generic/Icon"
import { ColorScheme, colorSchemes, setColorScheme, useColorScheme } from "../state/colorScheme"
import { useMounted } from "../utils/useMounted"

export const ThemeSelect: FC = () => {
  const T = useLocalizedText({ de, en })
  const colorScheme = useColorScheme()

  const isDark =
    colorScheme === "dark" || (colorScheme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches)

  useEffect(() => {
    const classList = document.documentElement.classList

    if (isDark) {
      if (!classList.contains("dark")) {
        classList.add("dark")
      }
    } else {
      classList.remove("dark")
    }
  }, [isDark])

  const options = colorSchemes.reduce<OptionData[]>((options, scheme) => {
    options.push({
      value: scheme,
      text: T.colorScheme[scheme],
    })
    return options
  }, [])

  return (
    <section aria-label={T.landmark} className="flex gap-2 items-center">
      <Icon name={isDark ? "moonhalf" : "sun"} className="w-8" />

      <Select
        options={options}
        value={colorScheme}
        onChange={setColorScheme}
        title={T.changeLocale}
        aria-label={T.changeLocale}
      />
    </section>
  )
}

const de = {
  landmark: "Farbmodus",
  changeLocale: "Zwischen hell und dunkel wechseln",
  colorScheme: {
    system: "System",
    light: "Hell",
    dark: "Dunkel",
  } satisfies Record<ColorScheme, string>,
}

const en: typeof de = {
  landmark: "Color Scheme",
  changeLocale: "Switch between light and dark",
  colorScheme: {
    system: "System",
    light: "Light",
    dark: "Dark",
  },
}
