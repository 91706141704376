import { create as createStore } from "zustand"
import { persist } from "zustand/middleware"

import { useIsLgAndUp } from "src/components/utils/useMediaQuery"

const resultsVariants = ["list", "table"] as const

export type ResultsVariant = (typeof resultsVariants)[number]

type State = {
  variant: ResultsVariant
}

const defaultState: State = {
  variant: "list",
}

const store = createStore(persist(() => defaultState, { name: "results-variant" }))

export function setPreferredResultsVariant(variant: ResultsVariant) {
  store.setState({ variant })
}

export function usePreferredResultsVariant() {
  return store((state) => state.variant)
}

export function useResultsVariant(): ResultsVariant {
  const variant = usePreferredResultsVariant()
  const isLargeScreen = useIsLgAndUp()

  if (variant === "table" && isLargeScreen) {
    return "table"
  }

  return "list"
}
