import { create as createStore } from "zustand"

import { SearchResults } from "src/types/app"

export type SearchResultsState = SearchResults

function createInitialState(): SearchResultsState {
  return {
    offset: 0,
    limit: 0,
    count: 0,
    hits: [],
  }
}

const defaultState: SearchResultsState = createInitialState()

const store = createStore(() => defaultState)

export function getSearchResults() {
  return store.getState()
}

export function setSearchResults(state: SearchResultsState) {
  const replace = true
  store.setState(state, replace)
}

export function resetSearchResults() {
  setSearchResults(createInitialState())
}

export function useSearchResults() {
  return store()
}
