import { create as createStore } from "zustand"
import { persist } from "zustand/middleware"
import { useMounted } from "../utils/useMounted"

export const colorSchemes = ["system", "light", "dark"] as const

export type ColorScheme = (typeof colorSchemes)[number]

type State = {
  colorscheme: ColorScheme
}

const defaultState: State = {
  colorscheme: "system",
}

const store = createStore(persist(() => defaultState, { name: "color-scheme" }))

export function setColorScheme(colorscheme: ColorScheme) {
  store.setState({ colorscheme })
}

export function useColorScheme() {
  const mounted = useMounted()
  const colorscheme = store((state) => state.colorscheme)

  if (!mounted) {
    return "light"
  }

  return colorscheme
}
