import { useRouter } from "next/router"

import { AppLocale, DateLocale, appLocales } from "src/config"

export type LocalizedTexts<T> = Record<AppLocale, T>

export function useLocale(): AppLocale {
  const router = useRouter()
  return (router.locale as AppLocale) || appLocales[0]
}

export function appLocaleToDateLocale(locale: AppLocale): DateLocale {
  return locale === "en" ? "en-GB" : "de-DE"
}

export function useDateLocale() {
  const locale = useLocale()
  return appLocaleToDateLocale(locale)
}

export function useLocalizedText<T>(translations: LocalizedTexts<T>) {
  const locale = useLocale()
  return translations[locale]
}
