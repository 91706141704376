import clsx from "clsx"
import { FC } from "react"

import { OptionData } from "src/types/app"

import { Icon } from "./Icon"

export type SelectProps = {
  title: string
  options: OptionData[]
  value: any
  onChange: (value: any) => void
  className?: string
}

export const Select: FC<SelectProps> = ({ title, options, value, onChange, className }) => (
  <div className={clsx("relative rounded text-black dark:text-white", className)}>
    <select
      value={value}
      onChange={(event) => onChange(event.target.value)}
      title={title}
      className={clsx("h-control-md", className)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>

    <Icon name="arrowdown" className="pointer-events-none absolute right-2 top-0 h-control-md w-icon" />
  </div>
)
