import androidChrome192x192 from "resources/logos/generated/android-chrome-192x192.png"
import androidChrome512x512 from "resources/logos/generated/android-chrome-512x512.png"
import appleTouchIcon from "resources/logos/generated/apple-touch-icon.png"
import favicon16x16 from "resources/logos/generated/favicon-16x16.png"
import favicon32x32 from "resources/logos/generated/favicon-32x32.png"
import logoBanner96Dark from "resources/logos/generated/logo-banner-96-dark.png"
import logoBanner96 from "resources/logos/generated/logo-banner-96.png"
import openGraphBanner from "resources/logos/generated/open-graph-banner.png"
import safariPinnedTab from "resources/logos/generated/safari-pinned-tab.svg"

export const appIcons = {
  androidChrome192x192: androidChrome192x192.src,
  androidChrome512x512: androidChrome512x512.src,
  appleTouchIcon: appleTouchIcon.src,
  favicon16x16: favicon16x16.src,
  favicon32x32: favicon32x32.src,
  logoBanner96: logoBanner96.src,
  logoBanner96Dark: logoBanner96Dark.src,
  openGraphBanner: openGraphBanner.src,
  safariPinnedTab: safariPinnedTab.src,
} as const
