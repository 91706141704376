export const filesOrigin = "https://info2.sermon-online.com"
export const canonicalOrigin = "https://www.sermon-online.com"
export const defaultFilterValue = "null"
export const defaultResultsPerPage = 25
export const maxResultsPerPage = 250

export const emails = {
  legal: "webmaster@sermon-online.de",
  technical: "webmaster@sermon-online.de",
  orders: "order@sermon-online.de",
}

export const appLocales = ["en", "de"] as const

export type AppLocale = (typeof appLocales)[number]

export type DateLocale = "en-GB" | "de-DE"

export const localeToLanguageIdMap: { [key in AppLocale]: number } = {
  en: 25,
  de: 24,
}

export const sortOrders = ["relevance", "contentDate", "dateAdded"] as const

export type SortOrder = (typeof sortOrders)[number]

export const appRoutes = {
  authors: "/authors",
  authorsReverse: "/authors/reverse",
  bibleBooks: "/bible/books",
  categories: "/categories",
  contents: "/contents",
  faq: "/faq",
  home: "/home",
  languages: "/languages",
  legal: "/legal",
  search: "/search",
  support: "/support",
  directory: "https://dir.sermon-online.com",
  topics: "/topics",
  download: "/download",
  new: "/search",
  notFound: "/404",
  error: "/500",
  webmanifest: "/api/site.webmanifest",
  v1_de: "https://v1.sermon-online.de",
  v1_en: "https://v1.sermon-online.com",
} as const

export const apiRoutes = {
  sitemap: {
    index: "/api/sitemap",
    app: "/api/sitemap/app",
    contentIdRange: (contentIdRange: string) => `/api/sitemap/${contentIdRange}`,
  },
  robots: "/api/robots",
  search: "/api/search",
  site: "/api/site.webmanifest",
}

export type AppRoute = keyof typeof appRoutes

export const footerRoutes: AppRoute[] = ["home", "faq", "support", "legal", "directory"]

export const appConfig = {
  siteName: "Sermon-Online",
  siteNameShort: "SermonOnline",
  themeColor: "#8C3462",
  backgroundColor: "#ffffff",
} as const

export const sectionIds = {
  hits: "hits",
}
