import { omitBy } from "lodash-es"
import { stringify } from "mini-querystring"

import { appRoutes, defaultFilterValue, filesOrigin } from "src/config"
import {
  BibleBookData,
  ClientSideFilterData,
  ContentAuthorData,
  ContentCategoryData,
  ContentLanguageData,
  ContentMediaTypeData,
  FileData,
} from "src/types/app"

function isEmptyParam(value: any) {
  return value === undefined || value === null || value === defaultFilterValue || value === ""
}

function cleanUrlParams(params: Record<string, any>) {
  return omitBy(params, isEmptyParam)
}

export function formatSearchUrl(filters: Partial<ClientSideFilterData>) {
  const cleanedFilters = cleanUrlParams(filters)
  const query = stringify(cleanedFilters)
  return appRoutes.search + (query ? `?${query}` : "")
}

export function formatQueryUrl(q: string) {
  return formatSearchUrl({ q })
}

export function formatAuthorUrl(data: ContentAuthorData) {
  return formatSearchUrl({ author: data.id })
}

export function formatBibleBookUrl(data: BibleBookData) {
  return formatSearchUrl({ q: data.name })
}

export function formatCategoryUrl(data: ContentCategoryData) {
  return formatSearchUrl({ category: data.id })
}

export function formatMediaTypeUrl(data: ContentMediaTypeData) {
  return formatSearchUrl({ medium: data.id })
}

export function formatLanguageUrl(data: ContentLanguageData) {
  return formatSearchUrl({ language: data.id })
}

export function formatTopicUrl(data: { query: string }) {
  return `${appRoutes.search}?${data.query}`
}

export function formatContentUrl(id: string | number) {
  return `${appRoutes.contents}/${id}`
}

export function formatFileOpenUrl(file: FileData) {
  const isExternal = file.filename.startsWith("http")
  return isExternal ? file.filename : filesOrigin + file.path
}

export function formatFileDownloadUrl(file: FileData) {
  return appRoutes.download + file.path
}

export function formatHexaplaUrl(bookNum: string, chapterNum: string | undefined, verseStartNum: string | undefined) {
  const hexaplaId = [
    bookNum.padStart(2, "0"),
    (chapterNum || "1").padStart(3, "0"),
    (verseStartNum || "1").padStart(3, "0"),
  ].join("")

  return filesOrigin + `/german/Bible/Hexapla/${hexaplaId}.html`
}
