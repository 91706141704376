import { AppProps } from "next/app"
import { FC } from "react"

import { BackToTop } from "src/components/generic/BackToTop"
import { Footer } from "src/components/shared/Footer"
import { Header } from "src/components/shared/Header"
import { useSearchUpdates } from "src/components/state/useSearchUpdates"
import { useMounted } from "src/components/utils/useMounted"
import "src/styles/index.css"
import { SharedPageProps } from "src/types/app"

const App: FC<AppProps<SharedPageProps>> = ({ Component, pageProps }) => {
  const { stats } = pageProps as SharedPageProps
  const mounted = useMounted()

  useSearchUpdates()

  // This makes the dark theme transition less noticable.
  const visibility = mounted ? "visible" : "hidden"

  return (
    <div className="flex min-h-screen w-full flex-col" style={{ visibility }}>
      <Header />

      <div className="flex-1 pb-pageBottom pt-pageY">
        <Component {...pageProps} />
      </div>

      <BackToTop />

      <Footer stats={stats} />
    </div>
  )
}

export default App
