import { useEffect, useState } from "react"

const breakpoints = {
  zero: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)

    function listener() {
      if (media.matches !== matches) {
        setMatches(media.matches)
      }
    }

    listener()
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [matches, query])

  return matches
}

export function useIsSmAndUp() {
  return useMediaQuery(`(min-width: ${breakpoints.sm}px)`)
}

export function useIsMdAndUp() {
  return useMediaQuery(`(min-width: ${breakpoints.md}px)`)
}

export function useIsLgAndUp() {
  return useMediaQuery(`(min-width: ${breakpoints.lg}px)`)
}

export function useIsXlAndUp() {
  return useMediaQuery(`(min-width: ${breakpoints.xl}px)`)
}

export function useIs2xlAndUp() {
  return useMediaQuery(`(min-width: ${breakpoints.xxl}px)`)
}
