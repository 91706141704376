import clsx from "clsx"
import { FC } from "react"

import { IconName } from "resources/icons/sprite"
import spriteUrl from "resources/icons/sprite.svg"

export type { IconName }

export type IconProps = {
  name: IconName
  className?: string
}

export const Icon: FC<IconProps> = ({ name, className }) => (
  <svg
    className={clsx("pointer-events-none select-none fill-current", className)}
    width="48"
    height="48"
    aria-hidden="true"
  >
    <use href={`${spriteUrl}#${name}`} />
  </svg>
)
