import { FC } from "react"

import { appIcons } from "src/components/shared/appIcons"
import { useLocalizedText } from "src/components/state/localization"

export const LogoPanorama: FC = () => {
  const T = useLocalizedText({ de, en })

  return (
    <>
      <img src={appIcons.logoBanner96} alt={T.logoaltlt} height={40} width={194} className="flex dark:hidden" />
      <img
        src={appIcons.logoBanner96Dark}
        alt={T.logoaltlt}
        height={40}
        width={194}
        aria-hidden="true"
        className="hidden dark:flex"
      />
    </>
  )
}

const de = {
  logoaltlt: "Sermon-Online Schriftzug",
}

const en: typeof de = {
  logoaltlt: "Sermon-Online Lettering",
}
