import { debounce, isEqual } from "lodash-es"
import Router from "next/router"
import { create as createStore } from "zustand"

import { formatSearchUrl } from "src/components/utils/links"
import { AppLocale, appRoutes, localeToLanguageIdMap } from "src/config"
import { ClientSideFilterData } from "src/types/app"

export type FiltersState = ClientSideFilterData

const defaultState: FiltersState = {}

const store = createStore(() => defaultState)

export function getFilters() {
  return store.getState()
}

export function setFilters(state: FiltersState) {
  const replace = true
  store.setState(state, replace)
}

export function useFilters() {
  return store()
}

export async function mergeFiltersStateAndUpdateUrl(filters: Partial<ClientSideFilterData>) {
  const newState: FiltersState = {
    ...filters,
    page: undefined,
  }

  if (!isEqual(newState, getFilters())) {
    store.setState({
      ...filters,
      page: undefined,
    })

    await navigateToSearchResults()
  }
}

export const mergeFiltersStateAndUpdateUrlDebounced = debounce(mergeFiltersStateAndUpdateUrl, 100)

function getSearchResultsUrl() {
  const filters = getFilters()

  if (
    !Router.asPath.startsWith(appRoutes.search) &&
    Router.locale &&
    Router.locale !== Router.defaultLocale &&
    Router.locale in localeToLanguageIdMap
  ) {
    filters.language = localeToLanguageIdMap[Router.locale as AppLocale].toString()
  }

  return formatSearchUrl(filters)
}

export async function navigateToSearchResults() {
  const url = getSearchResultsUrl()

  await Router.push(url, url, { shallow: true })
}
