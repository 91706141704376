import clsx from "clsx"
import { FC, ReactNode } from "react"

export type HorizontalSpacingProps = {
  id?: string
  as?: "section" | "main"
  className?: string
  children: ReactNode
}

export const HorizontalSpacing: FC<HorizontalSpacingProps> = ({ id, as, className, children, ...htmlAttributes }) => {
  const Element = as || "div"

  return (
    <Element id={id} className={clsx("mx-auto w-full max-w-screen-lg px-pageX", className)} {...htmlAttributes}>
      {children}
    </Element>
  )
}
