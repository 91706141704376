import clsx from "clsx"
import { FC, useEffect, useState } from "react"

import { Icon } from "src/components/generic/Icon"
import { useLocalizedText } from "src/components/state/localization"
import { useResultsVariant } from "src/components/state/resultsVariant"

function scrollToTop() {
  window.scrollTo({ top: 0 })
}

function useCurrentY() {
  const [state, setstate] = useState(0)

  useEffect(() => {
    function listener() {
      setstate(window.scrollY)
    }

    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  }, [])

  return state
}

export const BackToTop: FC = () => {
  const T = useLocalizedText({ de, en })
  const currentY = useCurrentY()
  const variant = useResultsVariant()

  if (currentY < 100) {
    return null
  }

  return (
    <div className="pointer-events-none fixed bottom-[50vh] z-10 w-full translate-y-1/2">
      <div
        className={clsx("mx-auto w-full px-pageX", {
          "max-w-[1410px]": variant === "list",
        })}
      >
        <button
          type="button"
          title={T.backToTop}
          aria-label={T.backToTop}
          onClick={scrollToTop}
          className="Button pointer-events-auto ml-auto h-logo w-logo rounded-full p-0"
        >
          <Icon name="arrowup" className="h-icon w-icon" />
        </button>
      </div>
    </div>
  )
}

const de = {
  backToTop: "Zum Seitenanfang",
}

const en: typeof de = {
  backToTop: "Back to top",
}
