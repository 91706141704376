import { useRouter } from "next/router"
import { FC, useMemo } from "react"

import { A } from "src/components/generic/A"
import { HorizontalSpacing } from "src/components/generic/HorizontalSpacing"
import { LogoRounded } from "src/components/shared/LogoRounded"
import { routeTranslations } from "src/components/shared/routeTranslations"
import { useLocalizedText } from "src/components/state/localization"
import { appRoutes, footerRoutes } from "src/config"
import { StatsData } from "src/types/app"

import { LocaleSelect } from "./LocaleSelect"
import { StatsSection } from "./StatsSection"
import { ThemeSelect } from "./ThemeSelect"

function useLocalizedV1Origin() {
  const router = useRouter()
  return router.locale === "de" ? appRoutes.v1_de : appRoutes.v1_en
}

function useLinks() {
  const v1Origin = useLocalizedV1Origin()
  const T = useLocalizedText({ de, en })

  return useMemo(() => {
    const links: LinkProps[] = footerRoutes.map((route) => {
      const href = appRoutes[route]
      const text = route in T.routes ? T.routes[route] : "?"

      return { href, text }
    })

    links.push({
      href: v1Origin,
      text: T.backToV1,
    })

    return links
  }, [T, v1Origin])
}

const Locale: FC = () => (
  <div className="p-4 lg:py-0">
    <LocaleSelect />
  </div>
)

const Theme: FC = () => (
  <div className="p-4 lg:py-0">
    <ThemeSelect />
  </div>
)

interface LinkProps {
  href: string
  text: string
}

const Link: FC<LinkProps> = ({ href, text }) => (
  <li key={href} className="flex justify-center">
    <A
      href={href}
      className="flex flex-col items-center p-4 text-pink-300 text-center hover:underline lg:flex-row lg:py-2"
    >
      {text}
    </A>
  </li>
)

const Links: FC = () => {
  const links = useLinks()

  return (
    <nav>
      <ul className="grid items-center justify-center sm:grid-cols-3">
        {links.map((link) => (
          <Link key={link.href} {...link} />
        ))}
      </ul>
    </nav>
  )
}

export type StatsProps = {
  stats: StatsData
}

const Stats: FC<StatsProps> = ({ stats }) => (
  <div className="p-4 lg:py-0">
    <StatsSection stats={stats} />
  </div>
)

export type FooterProps = {
  stats: StatsData
}

export const Footer: FC<FooterProps> = ({ stats }) => {
  const T = useLocalizedText({ de, en })

  return (
    <footer className="w-full bg-pink-900 pb-pageBottom pt-pageY text-pink-300 content-visibility-auto lg:py-pageY dark:bg-black">
      <HorizontalSpacing>
        <div className="-mx-4 grid grid-cols-1 items-center justify-items-center gap-4 lg:grid-cols-auto-4 lg:justify-start">
          <A href={appRoutes.home} aria-label={T.routes.home} className="p-4">
            <LogoRounded />
          </A>
          <div className="flex flex-col gap-x-2">
            <Locale />
            <Theme />
          </div>
          <Links />
          <Stats stats={stats} />
        </div>
      </HorizontalSpacing>
    </footer>
  )
}

const de = {
  routes: routeTranslations.de,
  backToV1: "Alte Version",
}

const en: typeof de = {
  routes: routeTranslations.en,
  backToV1: "Old Version",
}
