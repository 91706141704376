import { isEqual } from "lodash-es"
import Router from "next/router"
import { useEffect } from "react"

import { getFilters, setFilters } from "src/components/state/filters"
import { setLoading } from "src/components/state/loading"
import { getResultsPerPage } from "src/components/state/resultsPerPage"
import { resetSearchResults, setSearchResults } from "src/components/state/searchResults"
import { apiRoutes, appRoutes, defaultResultsPerPage } from "src/config"
import { ClientSideFilterData, SearchResults } from "src/types/app"

const cache = new Map<string, SearchResults>()
let previousParamsStr: string | null = null

async function fetchResults(paramsStr: string) {
  setLoading(true)

  try {
    const response = await fetch(`${apiRoutes.search}?${paramsStr}`)

    // Due to type-as-you-search, the oder of the results can change.
    // Don't apply results if this is not the latest request.
    if (response.ok && paramsStr === previousParamsStr) {
      const results = await response.json()
      cache.set(paramsStr, results)
      setSearchResults(results)
    }
  } catch (error) {
    console.error(error)
  }

  setLoading(false)
}

function getTargetUrl(targetUrlWithLocalePrefix: string) {
  return targetUrlWithLocalePrefix.replace(/^\/[a-z]{2}\//, "/")
}

async function handleRouteChangeStart(targetUrlWithLocalePrefix: string) {
  const targetUrl = getTargetUrl(targetUrlWithLocalePrefix)
  const filtersUrlSegment = targetUrl.split("?")[1]
  const params = new URLSearchParams(filtersUrlSegment)
  const filters = Object.fromEntries(params.entries()) as ClientSideFilterData

  const pageNumber = +(filters.page || 1)
  const pageIndex = pageNumber - 1
  const pageSize = +(filters.size || getResultsPerPage() || defaultResultsPerPage)

  params.delete("page")
  params.delete("size")
  params.set("offset", `${pageIndex * pageSize}`)
  params.set("limit", `${pageSize}`)
  params.set("locale", Router.locale!)
  params.sort()

  const paramsStr = params.toString()

  // Remember which filters we used to fetch the current search results.
  if (paramsStr === previousParamsStr) {
    console.debug("Skipping search because the search params haven't changed:", paramsStr)
    return
  }

  previousParamsStr = paramsStr

  if (targetUrl.startsWith(appRoutes.search)) {
    // If the page is entered directly and not through client-side navigation,
    // the filters are in default state and need to be initialized.
    if (!isEqual(filters, getFilters())) {
      console.log("Initializing filters:", filters)
      setFilters(filters)
    }
  } else {
    console.debug("Skipping search results because we're not on the search page:", targetUrl)
    return
  }

  // Attempt to load the results from cache.
  if (cache.has(paramsStr)) {
    console.debug("Skipping search because the results are cached:", paramsStr)
    setSearchResults(cache.get(paramsStr)!)
    return
  }

  // If the results aren't cached, load them from the API.
  await fetchResults(paramsStr)
}

async function handleRouteChangeComplete(targetUrlWithLocalePrefix: string) {
  const targetUrl = getTargetUrl(targetUrlWithLocalePrefix)

  if (!targetUrl.startsWith(appRoutes.search)) {
    console.debug("Resetting search results beause we are leaving the search page.")
    resetSearchResults()
  }
}

export function useSearchUpdates() {
  useEffect(() => {
    handleRouteChangeStart(Router.asPath)

    Router.events.on("routeChangeStart", handleRouteChangeStart)
    Router.events.on("routeChangeComplete", handleRouteChangeComplete)
    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart)
      Router.events.off("routeChangeComplete", handleRouteChangeComplete)
    }
  }, [])
}
