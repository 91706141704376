import { FC } from "react"

import { appIcons } from "src/components/shared/appIcons"
import { useLocalizedText } from "src/components/state/localization"

export const LogoRounded: FC = () => {
  const T = useLocalizedText({ de, en })

  return <img src={appIcons.appleTouchIcon} alt={T.alt} width={48} height={48} className="h-logo w-logo rounded-full" />
}

const de = {
  alt: "Sermon-Online Logo",
}

const en: typeof de = {
  alt: "Sermon-Online Logo",
}
