import { create as createStore } from "zustand"
import { persist } from "zustand/middleware"

import { defaultResultsPerPage } from "src/config"

type State = {
  resultsPerPage: number
}

const defaultState: State = {
  resultsPerPage: defaultResultsPerPage,
}

const store = createStore(persist(() => defaultState, { name: "results-per-page" }))

export function getResultsPerPage() {
  store.getState().resultsPerPage
}

export function setResultsPerPage(resultsPerPage: number) {
  store.setState({ resultsPerPage })
}

export function useResultsPerPage() {
  return store((state) => state.resultsPerPage)
}
