import { appRoutes } from "src/config"

const de: Record<keyof typeof appRoutes, string> = {
  authors: "Autoren",
  authorsReverse: "Autoren",
  bibleBooks: "Bücher der Bibel",
  categories: "Kategorien",
  contents: "Inhalte",
  faq: "Fragen und Antworten",
  home: "Startseite",
  languages: "Sprachen",
  legal: "Impressum",
  search: "Suchergebnisse",
  support: "Unterstütze uns",
  directory: "Verzeichnis",
  topics: "Themen",
  download: "Herunterladen",
  new: "Neu",
  notFound: "Nicht gefunden",
  error: "Fehler",
  webmanifest: "Web Manifest",
  v1_de: "v1 Deutsch",
  v1_en: "v1 Englisch",
}

const en: typeof de = {
  authors: "Authors",
  authorsReverse: "Authors",
  bibleBooks: "Bible Books",
  categories: "Categories",
  contents: "Contents",
  faq: "Frequently Asked Questions",
  home: "Home",
  languages: "Languages",
  legal: "Legal",
  search: "Search",
  support: "Support Us",
  directory: "Directory",
  topics: "Topics",
  download: "Download",
  new: "New",
  notFound: "Not Found",
  error: "Error",
  webmanifest: "Webmanifest",
  v1_de: "v1 German",
  v1_en: "v1 English",
}

export const routeTranslations = { de, en }
