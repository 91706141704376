import { FC } from "react"

import { A } from "src/components/generic/A"
import { HorizontalSpacing } from "src/components/generic/HorizontalSpacing"
import { appRoutes } from "src/config"

import { LogoPanorama } from "./LogoPanorama"

export const Header: FC = () => {
  return (
    <header className="z-10 flex h-20 w-full items-center shadow dark:bg-black">
      <HorizontalSpacing>
        <nav className="-mx-4 max-w-screen-lg">
          <ul className="flex items-center">
            <li className="flex">
              <A href={appRoutes.home} aria-label="Zur Startseite" className="p-4">
                <LogoPanorama />
              </A>
            </li>
          </ul>
        </nav>
      </HorizontalSpacing>
    </header>
  )
}
